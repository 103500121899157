import React from "react";
import AboutUsComponent from "./AboutUsComponent";
import {
  MobileAppDevelopmentContainer,
  WebAppDevContainer,
} from "./AppDevelopmentComponent";
import BannerComponent from "./BannerComponent";
import ContactUsComponent from "./ContactUsComponent";
import "./home.scss";
import MissionVisionComponent from "./MissionVisionComponent";
import ServiceComponent from "./ServiceComponents";
import SolutionsComponent from "./SolutionsComponent";
import SuccessStoriesComponent from "./SuccessStoriesComponent";
import TeamMembersComponent from "./TeamMembers";
import TechnologyComponent from "./TechnologyComponent";
import TestServicesComponent from "./TestServicesComponent";
import UIUXComponent from "./UIUXComponent";
import WhyChooseUsComponent from "./WhyChooseUsComponent";
import CookieConsent from "react-cookie-consent";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import InView from "react-intersection-observer";

export class HomeComponent extends React.Component {
  constructor(props) {
    super();

    this.state = {
      modelOpen: false,
    };
  }

  componentDidMount() {
    const bodyEl = document.body;

    if (bodyEl) {
      bodyEl.scrollTop = 0;
    }
  }

  handleClick = () => {
    const cookieContainerEl = document.getElementsByClassName("CookieConsent");
    if (cookieContainerEl?.[0]) {
      cookieContainerEl[0].style.visibility = "hidden";
    }
    this.setState({ modelOpen: true });
  };

  handleClose = () => {
    const cookieContainerEl = document.getElementsByClassName("CookieConsent");
    if (cookieContainerEl?.[0]) {
      cookieContainerEl[0].style.visibility = "visible";
    }
    this.setState({ modelOpen: false });
  };

  handleAcceptClick = () => {
    const cookieAcceptEl = document.getElementById("rcc-confirm-button");

    if (cookieAcceptEl) {
      cookieAcceptEl.click();
    }
    this.setState({ modelOpen: false });
  };

  render() {
    const { modelOpen } = this.state;
    const { updateLocation } = this.props;

    return (
      <>
        <main id="home" className="home-container">
          <InView
            threshold={0.3}
            onChange={(inView) => (inView ? updateLocation("home") : null)}
          >
            <BannerComponent />
          </InView>
          <InView
            threshold={0.5}
            onChange={(inView) => (inView ? updateLocation("about-us") : null)}
          >
            <AboutUsComponent />
          </InView>
          <MissionVisionComponent />
          <WhyChooseUsComponent />
          <InView
            threshold={0.5}
            onChange={(inView) => (inView ? updateLocation("services") : null)}
          >
            <ServiceComponent />
          </InView>
          <UIUXComponent />
          <MobileAppDevelopmentContainer />
          <WebAppDevContainer />
          <TestServicesComponent />
          <SolutionsComponent />
          <InView
            threshold={0.5}
            onChange={(inView) =>
              inView ? updateLocation("technology") : null
            }
          >
            <TechnologyComponent />
          </InView>
          <SuccessStoriesComponent />
          <TeamMembersComponent />
          <InView
            threshold={0.5}
            onChange={(inView) => (inView ? updateLocation("contact") : null)}
          >
            <ContactUsComponent />
          </InView>
          <CookieConsent
            buttonText="Accept all cookies"
            style={{
              background: "#ffffff",
              width: "50%",
              minHeight: "122px",
              padding: "1rem 1.5rem",
              left: "0",
              borderRadius: "20px 0px 30px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              color: "#000000",
              alignItems: "center",
            }}
            buttonStyle={{
              color: "#ffffff",
              fontSize: "14px",
              backgroundColor: "#000000",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "0px 50px 50px 50px",
              minWidth: "177px",
              minHeight: "44px",
            }}
          >
            <h5>Cookies</h5>
            <p>
              This website uses cookies to ensure you get the best experience on
              our website.
              <a onClick={this.handleClick}>Learn more</a>
            </p>
          </CookieConsent>
        </main>
        <Modal
          isOpen={modelOpen}
          className="cookie-content-modal"
          centered={true}
        >
          <ModalHeader>Cookies</ModalHeader>
          <ModalBody>
            <h6>What is a cookie?</h6>
            <p>
              Cookies are small text files that are placed and stored on your
              terminal when you visit a website. A cookie allows its issuer to
              identify the terminal in which it is stored for the duration of
              the validity or the storage of this cookie.
              <br />
              The term “cookies” refers to all technologies allowing to trace
              the user’s navigation. The terminal refers to the equipment
              (computer, tablet, smartphone, etc.) you use to consult or view a
              website, an application or advertising content etc.
            </p>
            <h6>Why we are using cookies?</h6>
            <p>
              Cookies that do not require user’s consent
              <br />
              These are cookies that are not essential for browsing our website,
              but whose sole purpose is to enable or optimize its operation and
              to give you access to specific features. They also allow you to
              adapt the presentation of the website to the display preferences
              of your terminal. These cookies allow you to have a fluid and
              customized navigation.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClose}>Close</Button>{" "}
            <Button color="primary" onClick={this.handleAcceptClick}>
              Accept all cookies
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
