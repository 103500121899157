import { useState } from "react";
import InView from "react-intersection-observer";
import { titleLineIcon } from "../../assets/icons/iconCollection";
import "./styles.scss";

export const TitleRenderer = (props) => {
  const [animationStatus, setAnimationStatus] = useState(false);
  const { noBgText = false } = props;

  const titleLineImage = titleLineIcon({
    className: "title-line",
    width: "27px",
  });

  return (
    <InView
      as="div"
      onChange={(inView) =>
        !animationStatus ? setAnimationStatus(inView) : null
      }
    >
      <div
        className={`title-container ${props?.className || ""} ${
          animationStatus ? "animate-content" : ""
        }`}
      >
        <span className="title-content">
          {titleLineImage}
          <span className="front-display">{props?.title}</span>
          {titleLineImage}
        </span>
        {!noBgText && <span className="background-text">{props?.title}</span>}
      </div>
    </InView>
  );
};
