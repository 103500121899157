import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import { teamMembersData } from "./utils";

const TeamMembersComponent = (props) => {
  return (
    <section id="our-members-component" className="our-members-container">
      <TitleRenderer title="Our Members" />
      {/* <p>
        In publishing and graphic design, Lorem ipsum is a placeholder text
        commonly used to demonstrate the visual form of a document or a typeface
        without relying on meaningful content. Lorem ipsum may be used as a
        placeholder before the final copy is available. It is also used to
        temporarily replace text in a process called greeking, which allows
        designers to consider the form of a webpage or publication, without the
        meaning of the text influencing the design.
      </p> */}
      <div className="d-flex aligm-items-center members-content flex-wrap">
        {teamMembersData.map((data, index) => {
          return (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              key={`team-member-${index}`}
            >
              <img
                src={data.imgUrl}
                alt="member-image"
                className="team-member-dp"
              />
              <h5 className="member-name">{data.name}</h5>
              <p className="designation">{data.designation}</p>
              {/* <p className="desc">{data.description}</p> */}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TeamMembersComponent;
