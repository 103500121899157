import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import {
  cloudIcon,
  coneShapedIcon,
  customShapeDots,
  databaseIcon,
  developmentIcon,
  dotImageTech,
  doubleArrowTech,
  halfCircleIcon,
  odooIcon,
  ormToolsIcon,
  webToolsIcon,
} from "../../assets/icons/iconCollection";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";

const TechnologyComponent = (props) => {
  const databaseImage = databaseIcon({
    className: "db-image",
  });

  const developmentImage = developmentIcon({
    className: "dev-image",
  });

  const webToolsImage = webToolsIcon({
    className: "web-image",
  });

  const orlToolsImage = ormToolsIcon({
    className: "orm-tools-img",
  });

  const cloudImage = cloudIcon({
    className: "cloud-image",
  });

  const odooImage = odooIcon({
    className: "odoo-img",
  });

  const coneIcon = coneShapedIcon({
    className: "cone-icon-db",
  });

  const devCircleIcon = halfCircleIcon({
    className: "circle-icon-dev",
  });

  const doubleArrow = doubleArrowTech({
    className: "cloud-arrow",
  });

  const dotsGroupedImage = dotImageTech({
    className: "group-dots-tech",
  });

  const customShapeIcon = customShapeDots({
    className: "custom-shape-dots",
  });

  return (
    <section
      id="technology"
      className="technology-component-container position-relative"
    >
      <TitleRenderer title="Technology" />
      <div className="row technology-content-container">
        <div className="col-12 col-md-6 col-lg-4 db-content">
          <Card>
            <CardBody>
              {databaseImage}
              <span className="dots"></span>
              <CardTitle className="text-start" tag="h3">
                Databases
              </CardTitle>
              <CardText>MySQL | Oracle | Redis | PLSQL | Mongo DB</CardText>
              <div className="tools-image"></div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 dev-content">
          <Card>
            <CardBody>
              {developmentImage}
              <span className="dots"></span>
              <CardTitle tag="h3">Development tools</CardTitle>
              <CardText>
                Java | Spring Boot | NodeJs | Python | Go | Ruby | Swift
              </CardText>
              <div className="tools-image"></div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 web-tool-content">
          <Card>
            <CardBody>
              {webToolsImage}
              <span className="dots"></span>
              <CardTitle tag="h3">Web tools</CardTitle>
              <CardText>
                Angular | React | Ionic | WordPress | PHP | Flutter
              </CardText>
              <div className="tools-image"></div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 orm-tool-content">
          <Card>
            <CardBody>
              {orlToolsImage}
              <span className="dots animate"></span>
              <CardTitle tag="h3">ORM tools</CardTitle>
              <CardText>
                Hibernate | SpringDataJPA | Sequelize | MyBatis
              </CardText>
              <div className="tools-image"></div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 cloud-image-content">
          <Card>
            <CardBody>
              {cloudImage}
              <span className="dots animate"></span>
              <CardTitle tag="h3">Cloud</CardTitle>
              <CardText>
                In today’s world where physical space is pricey, we will have
                our expertise in AWS and Azure to take care of this worry.
              </CardText>
              <div className="tools-image"></div>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-6 col-lg-4 odoo-image-content">
          <Card>
            <CardBody>
              {odooImage}
              <span className="dots animate"></span>
              <CardTitle tag="h3">Solution Framework</CardTitle>
              <CardText>
                We also have our expertise in Odoo and Cuba open-source
                framework.
              </CardText>
              <div className="tools-image"></div>
            </CardBody>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default TechnologyComponent;
