import {
  accessibilityIcon,
  circleGroupIcon,
  dotsGroupHybrid,
  exploratoryTestingIcon,
  functionalTestingIcon,
  halfCircleIcon,
  loadPerformanceIcon,
  regressionTestingIcon,
  solutionsFooterBg,
  solutionsHeaderImage,
  testAutomationIcon,
  usabilityTestingIcon,
} from "../../assets/icons/iconCollection";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import { ZigZagRenderer } from "../../widgets/zigZagRender/ZigZagRenderer";

const SolutionsComponent = (props) => {
  const usabilityImage = usabilityTestingIcon({
    className: "usability-testing-icon",
  });

  const loadPerformanceImage = loadPerformanceIcon({
    className: "load-perf-icon",
  });

  const accessibilityImage = accessibilityIcon({
    className: "accessibility-icon",
  });

  const regressionTestImage = regressionTestingIcon({
    className: "regression-icon",
  });

  const exploratoryTestImage = exploratoryTestingIcon({
    className: "exploratory-testing-icon",
  });

  const solutionsHeaderBg = solutionsHeaderImage({
    className: "solutions-bg",
  });

  const dotsGroupSol = dotsGroupHybrid({
    className: "dots-group",
    pathfillcolor: "#FF9A9A",
  });

  const halfCircleImage = halfCircleIcon({
    className: "half-circle-solutions",
  });

  const solutionBgBottom = solutionsFooterBg({
    className: "solutions-footer",
  });

  const circleIcon = circleGroupIcon({
    className: "circle-group",
  });

  const functionalTestSvg = functionalTestingIcon({
    className: "function-test",
  });

  const testAutomationSvg = testAutomationIcon({
    className: "test-automation",
  });

  const contentList = [
    {
      title: "Functional Testing",
      description: `The functional testing services focus on testing the software against defined specifications and meeting 
      end-user expectations for functionality. We as a team to test the product early in the software development life cycle to 
      prevent and detect defects early so you can reduce your testing costs, rework expenses, and improve software quality.<br/><br/>
      Our functional testing approach supports different development methodologies. This is evident in how we align with the team 
      whether it is waterfall, agile, DevOps, or some combination. We utilize proven industry standard testing techniques, tools, 
      and methodologies to ensure thorough functional testing.`,
      icon: functionalTestSvg,
    },
    {
      title: "Test Automation Services",
      description: `Don’t let the website or app become a victim of its own success. Be sure that the system is up to handling 
      anticipated traffic, as well as peaks beyond what you anticipate, with well-orchestrated load and performance testing.`,
      icon: testAutomationSvg,
    },
    {
      title: "Usability Testing",
      description: `The user interface must speak both logically and intuitively to users. 
        There’s no substitute for usability testing to verify the interface operation, 
        check that each control does what is expected, and ensure all elements of the UI are in a 
        location and format the typical user expects.`,
      icon: usabilityImage,
    },
    {
      title: "Regression Testing",
      description: `Regression testing to verify that the bug fixes don't cause other problems<br/><br />
        The development and QA teams work hard to find and fix every defect in the code releases. 
        A key part of that effort is creating tests that verify those fixes and ensure that they 
        correct the bugs without damaging other functionality.`,
      icon: regressionTestImage,
    },
    {
      title: "Accessibility Testing",
      description: `Accessibility Testing for Equal Access to the Website and Mobile App <br /><br />
        With hundreds of millions of visually, hearing or mobility impaired people worldwide, it's 
        critical that the web and mobile applications be easy to access for those with disabilities. 
        Accessibility testing determines how well the website or mobile application can be understood, 
        navigated and used, regardless of the technology or platform used to access it.`,
      icon: accessibilityImage,
    },
    {
      title: "Load & Performance Testing",
      description: `Don’t let the website or app become a victim of its own success. 
        Be sure that the system is up to handling anticipated traffic, as well as peaks 
        beyond what you anticipate, with well-orchestrated load and performance testing.`,
      icon: loadPerformanceImage,
    },
    {
      title: "Exploratory Testing",
      description: `Exploratory testing services gives our team the full picture, to fix 
        defects and discover any collateral damage. We take a risk-based approach to exploratory 
        testing to find critical bugs that might not be found through traditional, scripted testing.`,
      icon: exploratoryTestImage,
    },
  ];

  return (
    <section id="solutions" className="solutions-component-container">
      <TitleRenderer title="Test Solutions" />
      {dotsGroupSol}
      <div className="row solutions-content-container">
        <ZigZagRenderer contentList={contentList} />
      </div>
    </section>
  );
};

export default SolutionsComponent;
