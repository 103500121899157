import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import React from "react";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import {
  mobileTypeIcon,
  mobileWebTypeIcon,
  newShapeImage,
  webTypeIcon,
} from "../../assets/icons/iconCollection";
import costCalcSvg from "../../assets/images/group-person.svg";
import androidDeviceIcon from "../../assets/images/android-icon.svg";
import iosDeviceIcon from "../../assets/images/ios-icon.svg";
import { questionList } from "./questionList";
import QuestionRenderer from "../../widgets/questionRenderer/QuestionRenderer";
import { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import { fetchWithPostMethod } from "../../common/fetchApi";
import { useAlert } from "react-alert";
import { RevolvingDot } from "react-loader-spinner";
import "./cost-calculator.scss";

const listDataQuestions = questionList.filter((data) => !data.isHide);

const ProjectCostCalculator = (props) => {
  const projectCalculatorContainerRef = useRef();
  const [answerValues, setAnswerValues] = useState({});
  const [modelOpen, setModelOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [answerDataForm, setAnswerDataForm] = useState({});
  const [isChecked, handledAgreeToggle] = useState(false);
  const [isSubmitted, handleIsSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const alertTemp = useAlert();

  useEffect(() => {
    const bodyEl = document.body;
    const costCalculatorBtn = document.getElementsByClassName(
      "cost-calculator-button"
    )?.[0];

    if (bodyEl) {
      bodyEl.scrollTop = 0;
    }

    if (costCalculatorBtn) {
      costCalculatorBtn.remove();
    }

    if(props?.handleHashChange) {
      props.handleHashChange('cost');
    }
  }, []);

  const treeViewShape = newShapeImage({
    className: "tree-view-shape",
  });

  const mobileTypeSvg = mobileTypeIcon({
    className: "mobile-type",
  });

  const webTypeSvg = webTypeIcon({
    className: "web-type",
  });

  const mobileWebTypeSvg = mobileWebTypeIcon({
    className: "mobile-web",
  });

  const handleAnswerChange = (answerData, questionId) => {
    if (questionId) {
      answerValues[questionId] = answerValues[questionId] || {};
      answerValues[questionId] = {
        answer: answerData,
      };
    }
    setAnswerValues(answerValues);
  };

  const handleRadioInputChange = (questionId, value, event) => {
    const radioInputAnswerValue = {...answerValues};
    radioInputAnswerValue[questionId] = radioInputAnswerValue[questionId] || {};
    radioInputAnswerValue[questionId] = {
      answer: [value],
    };
    setAnswerValues(radioInputAnswerValue);
  };

  const submitForm = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (Object.keys(answerValues)?.length > 0) {
      setModelOpen(true);
    }
  };

  const onQuickQuoteBtnClick = () => {
    setModelOpen(true);
  };

  const handleSubmitClick = () => {
    handleIsSubmit(true);

    if (answerDataForm?.firstName && answerDataForm?.email && isChecked) {
      setLoading(true);
      const formattedAnswers = [];

      Object.keys(answerValues).forEach((key, index) => {
        let questionAndAnswer = {};
        questionList.forEach((questionData) => {
          if (questionData.id == key) {
            questionAndAnswer.question = questionData.questionText;
            questionAndAnswer.answer =
              answerValues[key]?.answer?.join(",") || "";
          }
        });
        if (Object.keys(questionAndAnswer).length > 0) {
          formattedAnswers.push(questionAndAnswer);
        }
      });

      const requestParams = {
        ...answerDataForm,
        mobile: phoneNumber,
        questionAndAnswer: [...formattedAnswers],
      };

      if (phoneNumber) {
        requestParams.mobile = phoneNumber;
      }

      fetchWithPostMethod("/projectcostcalculator", requestParams)
        .then((res) => {
          handleIsSubmit(false);
          setAnswerValues({});
          setModelOpen(false);
          setPhoneNumber("");
          setAnswerDataForm({});
          handledAgreeToggle(false);
          setLoading(false);
          setIsFormSubmitted(true);
          setTimeout(() => {
            setIsFormSubmitted(false);
          }, 500);
          alertTemp.show(
            "Thanks for providing the details. Our support team will reach you soon.",
            {
              position: "top center",
              timeout: 4000,
            }
          );
        })
        .catch((err) => {
          setLoading(false);
          alertTemp.error("Error occurred while submitting the information", {
            position: "top center",
            timeout: 4000,
          });
        });
    }
  };

  const handleClose = () => {
    handleIsSubmit(false);
    setModelOpen(false);
  };

  const handleRadioBtnClick = (fieldName, value, event) => {
    const dataValues = {
      ...answerDataForm,
    };
    dataValues[fieldName] = value;
    setAnswerDataForm(dataValues);
  };

  const handleContactInfoChange = (fieldName, event) => {
    if (event) {
      event.preventDefault();
    }
    const dataValues = {
      ...answerDataForm,
    };
    dataValues[fieldName] = event?.target?.value;
    setAnswerDataForm(dataValues);
  };

  const handleNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const getIsPatternInvalid = (email) => {
    return !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email);
  };

  const getIsChecked = (value) => {
    return answerValues?.['98']?.answer?.[0] === value
  }

  return (
    <section
      id="project-cost-section"
      ref={projectCalculatorContainerRef}
      className="project-cost-container"
    >
      <div className="content-container">
        {treeViewShape}
        <TitleRenderer title="Project Cost Calculator" className="cost-title" />
        <img src={costCalcSvg} className="cost-calc-image" />
        <div className="question-list-container">
          <h1>Have an idea for a project?</h1>
          <p>
            Choose the features you want to be in your project to estimate the
            idea, see the price of your future project and get started with the
            development.
          </p>
          <Button className="quick-quote" onClick={onQuickQuoteBtnClick}>
            Quick Quote
          </Button>
          <Form>
            <div className="question-device">
              <h1>I want build</h1>
              <FormGroup
                tag="fieldset"
                className="d-flex align-items-center justify-content-center project-type"
                key="98"
              >
                <FormGroup check>
                  <Label htmlFor="mobileBuild" check>
                    {mobileTypeSvg}
                    <br />
                    <br />
                    Mobile
                  </Label>
                  <br />
                  <Input
                    id="mobileBuild"
                    onClick={(event) =>
                      handleRadioInputChange("98", "mobile", event)
                    } 
                    name="type"
                    type="radio"
                    checked={getIsChecked('mobile')} 
                  />
                </FormGroup>
                <FormGroup check>
                  <Label htmlFor="webBuild" check>
                    {webTypeSvg}
                    <br />
                    <br />
                    Web Project
                  </Label>
                  <br />
                  <Input
                    id="webBuild"
                    onClick={(event) =>
                      handleRadioInputChange("98", "web", event)
                    } 
                    name="type" 
                    type="radio" 
                    checked={getIsChecked('web')} 
                  />
                </FormGroup>
                <FormGroup check>
                  <Label htmlFor="mobileWebBuild" check>
                    {mobileWebTypeSvg}
                    <br />
                    <br />
                    Both
                  </Label>
                  <br />
                  <Input
                    id="mobileWebBuild"
                    onClick={(event) =>
                      handleRadioInputChange("98", "mobile&web", event)
                    } 
                    name="type" 
                    type="radio" 
                    checked={getIsChecked('mobile&web')} 
                  />
                </FormGroup>
              </FormGroup>
            </div>
            <div className="question-platform">
              <h4>Platform</h4>
              <FormGroup
                tag="fieldset"
                className="d-flex align-items-center justify-content-center project-platform-type"
                key="99"
              >
                <FormGroup
                  onClick={(event) =>
                    handleRadioInputChange("99", "android", event)
                  }
                  className="d-flex align-items-center justify-content-center"
                  check
                >
                  <Input                  
                    id="androidPlatform"
                    name="platform" 
                    type="radio" 
                  />{" "}
                  <Label htmlFor="androidPlatform" check>
                    <img
                      src={androidDeviceIcon}
                      className="android-icon"
                      alt="android"
                    />
                  </Label>
                </FormGroup>
                <FormGroup
                  onClick={(event) =>
                    handleRadioInputChange("99", "ios", event)
                  }
                  className="d-flex align-items-center justify-content-center"
                  check
                >
                  <Input id="iosPlatform" name="platform" type="radio" />{" "}
                  <Label htmlFor="iosPlatform" check>
                    <img src={iosDeviceIcon} className="ios-icon" alt="ios" />
                  </Label>
                </FormGroup>
                <FormGroup
                  onClick={(event) =>
                    handleRadioInputChange("99", "android&ios", event)
                  }
                  className="d-flex align-items-center justify-content-center"
                  check
                >
                  <Input id="both" name="platform" type="radio" /> <Label htmlFor="both" check>Both</Label>
                </FormGroup>
              </FormGroup>
            </div>
            <div className="question-list-content">
              {listDataQuestions.map((listData, listIndex) => {
                return (
                  <QuestionRenderer
                    {...listData}
                    isFormSubmit={isFormSubmitted}
                    key={listIndex}
                    handleAnswerDataChange={handleAnswerChange}
                  />
                );
              })}
            </div>
            <FormGroup onClick={submitForm}>
              <Button className="submit-button">Submit</Button>
            </FormGroup>
          </Form>
        </div>
      </div>
      <Modal isOpen={modelOpen} className="project-quote-modal" centered={true}>
        <ModalBody>
          <h6 className="title">
            Let us know where we should send your complete estimation
          </h6>
          <Form>
            <div className="row">
              <FormGroup className="col-12 col-md-6">
                <Input
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  invalid={!answerDataForm["firstName"] && isSubmitted}
                  onChange={(event) =>
                    handleContactInfoChange("firstName", event)
                  }
                />
                <FormFeedback>First name is required</FormFeedback>
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Input
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  invalid={!answerDataForm["lastName"] && isSubmitted}
                  onChange={(event) =>
                    handleContactInfoChange("lastName", event)
                  }
                />
                <FormFeedback>Last name is required</FormFeedback>
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12 col-md-6">
                <Input
                  id="company"
                  name="company"
                  placeholder="Company"
                  type="text"
                  invalid={!answerDataForm["company"] && isSubmitted}
                  onChange={(event) =>
                    handleContactInfoChange("company", event)
                  }
                />
                <FormFeedback>Company name is required</FormFeedback>
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Input
                  id="jobTitle"
                  name="jobTitle"
                  placeholder="Job Title"
                  type="text"
                  invalid={!answerDataForm["jobTitle"] && isSubmitted}
                  onChange={(event) =>
                    handleContactInfoChange("jobTitle", event)
                  }
                />
                <FormFeedback>Job title is required</FormFeedback>
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12 col-md-6">
                <PhoneInput
                  defaultCountry="IN"
                  placeholder="Contact Number"
                  value={phoneNumber}
                  onChange={handleNumberChange}
                />
                <FormFeedback className="phone-input-invalid" style={
                { 
                  'display': (!phoneNumber && isSubmitted ? 'block' : '')
                }
                }>Phone number is required</FormFeedback>
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  invalid={
                    getIsPatternInvalid(answerDataForm["email"]) && isSubmitted
                  }
                  onChange={(event) => handleContactInfoChange("email", event)}
                />
                <FormFeedback>
                  {!answerDataForm["email"]
                    ? "Email is required"
                    : "Invalid email input."}
                </FormFeedback>
              </FormGroup>
            </div>
            <FormGroup>
              <Input
                id="desc"
                name="desc"
                placeholder="Provide us with some information about your project"
                type="textarea"
                invalid={!answerDataForm["description"] && isSubmitted}
                onChange={(event) =>
                  handleContactInfoChange("description", event)
                }
              />
              <FormFeedback>Project description is required</FormFeedback>
            </FormGroup>
            <FormGroup 
              className="question-start-duration" 
              tag="fieldset"
            >
              <legend>When would you like to start?</legend>
              <FormGroup check>
                <Input
                  name="startDuration"
                  type="radio"
                  invalid={!answerDataForm["startDuration"] && isSubmitted}
                  onChange={(event) =>
                    handleRadioBtnClick("startDuration", "fewWeeks", event)
                  }
                  checked={answerDataForm?.["startDuration"] === "fewWeeks"}
                />{" "}
                <Label
                  onClick={(event) =>
                    handleRadioBtnClick("startDuration", "fewWeeks", event)
                  }
                  check={true}
                >
                  Within the next few weeks
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  name="startDuration"
                  type="radio"
                  invalid={!answerDataForm["startDuration"] && isSubmitted}
                  onChange={(event) =>
                    handleRadioBtnClick("startDuration", "fewMonths", event)
                  }
                  checked={answerDataForm?.["startDuration"] === "fewMonths"}
                />{" "}
                <Label
                  onClick={(event) =>
                    handleRadioBtnClick("startDuration", "fewMonths", event)
                  }
                  check
                >
                  Within the next few months
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  name="startDuration"
                  type="radio"
                  onChange={(event) =>
                    handleRadioBtnClick("startDuration", "dontKnow", event)
                  }
                  checked={answerDataForm?.["startDuration"] === "dontKnow"}
                />{" "}
                <Label
                  onClick={(event) =>
                    handleRadioBtnClick("startDuration", "dontKnow", event)
                  }
                  check
                >
                  I don’t know
                </Label>
              </FormGroup>
              <FormFeedback style={
                { 
                  'display': (!answerDataForm["startDuration"] && isSubmitted ? 'block' : ''),
                  'bottom': '-15px'
                }
                }>
                Please provide when you would like to start the project
              </FormFeedback>
            </FormGroup>
            <FormGroup className="form-agree-input" check>
              <Input
                checked={isChecked}
                invalid={!isChecked && isSubmitted}
                onChange={() => handledAgreeToggle(!isChecked)}
                type="checkbox"
              />{" "}
              <Label onClick={() => handledAgreeToggle(!isChecked)} check>
                By submitting your form, you agree to the Labo Neft Privacy
                Policy.
              </Label>
              <FormFeedback>
                Please agree Labo Neft privacy policy before submit
              </FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="quote-submit"
            onClick={handleSubmitClick}
          >
            Click to get your free quote
          </Button>{" "}
          <Button className="cancel-button" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
        {isLoading ? (
          <div className="loading-modal">
            <RevolvingDot
              height="100"
              width="100"
              color="rgba(255,255,255,0.8)"
              ariaLabel="loading"
            />
          </div>
        ) : null}
      </Modal>
    </section>
  );
};

export default ProjectCostCalculator;
