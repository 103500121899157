import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import { Card, CardText, CardBody } from "reactstrap";
import hybridBg from "../../assets/images/hybrid.svg";
import iosBg from "../../assets/images/ios.svg";
import androidBg from "../../assets/images/android.svg";
import webBg from "../../assets/images/web.svg";
import {
  coneShapedIcon,
  contactGroupIcon,
  dotsGroupHybrid,
  circleGroupedBg,
  hybridGroupedShape,
  mobileAppGroupedIcon,
} from "../../assets/icons/iconCollection";

export const MobileAppDevelopmentContainer = (props) => {
  const groupedImage = mobileAppGroupedIcon({
    className: "icon-group-1",
  });

  const coneIcon = coneShapedIcon({
    width: "72px",
    height: "77px",
    pathfillcolor: "#FE5BB3",
    className: "cone-android",
  });

  const dotsGroupedImage = dotsGroupHybrid({
    className: "dots-group",
  });

  const contactGroupSvg = contactGroupIcon({
    className: "contact-icon",
  });

  const hybridShapedSvg = hybridGroupedShape({
    className: "hybrid-grouped-shape",
  });

  return (
    <section id="mobile-app-component-container" className="position-relative">
      <TitleRenderer title="Mobile App Development" />
      <section className="hybrid-app-container">
        <div className="d-flex">
          <img src={hybridBg} className="hybrid-bg" alt="hybrid-background" />
          <div className="hybrid-content-container">
            {groupedImage}
            <TitleRenderer
              title="Hybrid Apps"
              className="hybrid-section-title"
              noBgText={true}
            />
            <Card>
              <CardBody>
                <CardText>
                  Hybrid App is a software application that is a merge component
                  of web applications and native apps. The most loved element of
                  Hybrid apps in application development is that developers have
                  to write code once, applying it to several platforms. The code
                  is written in cross-platform applications like HTML,
                  JavaScript, and other programming languages. Therefore, it is
                  cost-effective and less time-consuming because there is no
                  requirement of writing different codes for multiple platforms.
                  Instead, it saves time and money for business owners by
                  applying the same code to multiple platforms. It is the best
                  of both worlds with less development cost and easily
                  accessible data.
                </CardText>
                <CardText>
                  Labo provides scalable and unmatchable mobile applications.
                  Our experts help to determine the right cross-platform
                  framework that matches your business requirements.
                </CardText>
                {dotsGroupedImage}
              </CardBody>
            </Card>
            {hybridShapedSvg}
          </div>
        </div>
      </section>
      <section id="android-app" className="android-app-container">
        <div className="d-flex">
          <div className="android-content-container">
            <TitleRenderer
              title="Android Apps"
              className="android-section-title"
              noBgText={true}
            />
            <Card>
              <CardBody>
                <CardText>
                  Android - which is a fast-growing operating system for
                  smartphone devices has been famous for its every update. Its
                  many-sided, user affable applications & flexibility has made
                  it more favourable. In this growing market, to find a suitable
                  partner who can provide expertise on Android app solution has
                  become crucial for start-ups, large and small organizations
                  across a wide range of business.
                </CardText>
                <CardText>
                  As a leading Android app development company and with an
                  experienced team of Android app developers, we solve business
                  problems and help start-ups & enterprises' problems with
                  Android technologies. Our team comprises of experienced
                  Android developers with great proficiency in the platform as
                  well as all its versions. Our extensive platform-expertise
                  makes us capable to provide a full range of android app
                  development services that are a perfect match for the client's
                  demands. We live up to mark for the clients' particular
                  project demands that help them to accomplish their desired
                  business aims.
                </CardText>
                {coneIcon}
              </CardBody>
            </Card>
          </div>
          <img
            src={androidBg}
            className="android-bg"
            alt="android-background"
          />
        </div>
      </section>
      <section id="ios-app" className="ios-app-container">
        <div className="d-flex">
          <img src={iosBg} className="ios-bg" alt="ios-background" />
          {contactGroupSvg}
          <div className="ios-content-container">
            <TitleRenderer
              title="IOS Apps"
              className="ios-section-title"
              noBgText={true}
            />
            <Card>
              <CardBody>
                <CardText>
                  The demand for iPhone devices is growing on a global scale as
                  this is judged to be the device for the elite. Businesses look
                  to profit from this popularity by reaching out to this user
                  group. However, iPhone apps are to be improved with the right
                  features and technologies to make an impact on the customers.
                  The design and the natural framework of iPhone apps have
                  forced designers to come up with new creative solutions.
                </CardText>
                <CardText>
                  Labo is one of the best equipped companies in iPhone app
                  development. We have mastered the art of blending knowledge
                  with the right skill to produce robust and sophisticated iOS
                  apps.
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
    </section>
  );
};

export const WebAppDevContainer = (props) => {
  const groupedCircleSvg = circleGroupedBg({
    className: "circle-group",
  });

  return (
    <section id="web-app-component-container">
      <TitleRenderer title="Web Development" />
      <div className="row position-relative">
        <div className="col-12 col-md-6">
          <img src={webBg} className="web-bg" alt="web-background" />
        </div>
        <div className="col-12 col-md-6 desc">
          {groupedCircleSvg}
          <p>
            The website has become an essential and significant part of human
            life. Every business needs a website to showcase its skill,
            products, talents, and much more. Everything can be formed and
            updated with a website. Labo understands the necessities and crafts
            exquisite website for start-ups or businesses.
          </p>

          <p>
            Our highly skilled developers have proficient expertise in the
            latest technology and trends. We transform recognized ideas into
            stunning & inventive websites. We have rich experience and
            creativity in producing phenomenal websites. Our experts hold a
            different and unique perspective which helps to tailor web
            development faster and better. With this web development, the
            business runs with high functionality, efficiency and enhances it.
          </p>
        </div>
      </div>
    </section>
  );
};
