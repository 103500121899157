import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import {
  doubleArrowTech,
  halfCircleIcon,
} from "../../assets/icons/iconCollection";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { serviceListData } from "./utils";

const listData = serviceListData();
const defaultSliderPercent = 33.33;

const ServiceComponent = (props) => {
  const halfCircleImage = halfCircleIcon({
    className: "circle-icon-services",
  });

  const doubleArrow = doubleArrowTech({
    className: "services-arrow",
  });

  const carouselProps = {
    autoPlay: true,
    showThumbs: false,
    showIndicators: false,
    showStatus: false,
    showArrows: false,
    centerMode: true,
    infiniteLoop: true,
    centerSlidePercentage:
      window.innerWidth < 768 ? "96%" : defaultSliderPercent,
    interval: 1500,
  };

  return (
    <section id="services" className="services-component-container">
      <TitleRenderer title="Offering" />
      {doubleArrow}
      {halfCircleImage}
      <div className="row services-list-content">
        <Carousel {...carouselProps}>
          {listData.map((data, index) => {
            return (
              <Card key={`service-${index}`}>
                <CardBody>
                  <CardTitle tag="h4">{data.title}</CardTitle>
                  {data.icon}
                  <CardText>{data.description}</CardText>
                </CardBody>
              </Card>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default ServiceComponent;
