import { Button, Card, CardText, CardBody, CardTitle } from "reactstrap";
import {
  bannerBackdropImage,
  bannerTreeDots,
  coneShapedIcon,
  designLineChanges,
  designLineCustom,
  designShapeIcon,
  dotsGroupedImage,
  dotsGroupYellow,
  doubleArrowIcon,
  halfCircleIcon,
  lineConnectImage,
  goalAppIcon,
  newShapeImage,
  symbolGroupIcon,
  transformIcon,
  webAppIcon,
  billIcon,
  billSuiteIcon,
  carouselArrowIcon,
  QRCodeIcon
} from "../../assets/icons/iconCollection";
import { InView } from "react-intersection-observer";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import QRCodeLogo from "../../assets/images/qr-code-logo.svg";
import ProductBg from "../../assets/images/product-bg-template.svg";

const BannerComponent = (props) => {
  const [mobileAppStatus, setMobileAppStatus] = useState(false);
  const [webAppStatus, setWebAppStatus] = useState(false);
  const [transformStatus, setTransformStatus] = useState(false);
  const [contentStatus, setContentStatus] = useState(false);

  const goalIcon = goalAppIcon({
    className: "mobile-icon",
  });

  const webAppCardIcon = webAppIcon({
    className: "web-app-icon",
  });

  const transformCardIcon = transformIcon({
    className: "transform-card-icon",
  });

  const coneIcon = coneShapedIcon({
    className: "cone-icon",
  });

  const webAppCircleIcon = halfCircleIcon({
    className: "circle-half-icon",
  });

  const doubleArrow = doubleArrowIcon({
    className: "double-arrow-icon",
  });

  const dotsImageTransform = dotsGroupedImage({
    className: "dots-group-transform",
  });

  const lineConnectIcon = lineConnectImage({
    className: "line-connect-image",
  });

  const bannerBg = bannerBackdropImage({
    className: "banner-bg-1",
  });

  const treeViewShape = newShapeImage({
    className: "tree-view-shape",
  });

  const bannerTreeView = bannerTreeDots({
    className: "banner-tree-group",
  });

  const yellowDotsGroup = dotsGroupYellow({
    className: "dots-bottom",
  });

  const shapeImage = designShapeIcon({
    className: "shape-icon",
  });

  const lineShape = designLineChanges({
    className: "design-line",
  });

  const lineShapeSec = designLineCustom({
    className: "line-custom",
  });

  const symbolGroupImage = symbolGroupIcon({
    className: "symbol-group",
  });

  const billIconImage = billIcon({
    className: "bill-icon"
  });

  const billSuiteImage = billSuiteIcon({
    className: "bill-suite-icon"
  })

  const carouselIcon = carouselArrowIcon({
    className: "carousel-arrow"
  });

  const QRCodeImage  = QRCodeIcon({
    className: "qr-code-icon"
  })

  const renderCarouselIndicator = (
    clickHandler,
    isSelected,
    index,
    label
  ) => {
    return (
      <span onClick={clickHandler} className={`${index === 1 ? 'next-arrow' : '' }`}>
        {carouselIcon}
      </span>
    )
  }

  const handleQRCodeClick = () => {
    window.open("https://www.qrcode.laboneft.com/" , "_blank");
  }

  const handleBillSuiteClick = () => {
    window.open("https://billsuite.laboneft.com" , "_blank");
  }

  return (
    <Carousel 
      interval={4000} 
      infiniteLoop={true}
      showArrows={false} 
      showStatus={false} 
      showThumbs={false}
      renderIndicator={renderCarouselIndicator}
    >
      <section id="banner-products" className="banner-component-container container-fluid">
        {bannerBg}
        {treeViewShape}
        {bannerTreeView}
        <div className="row">
          <div className="col-12 col-lg-6 header-title text-center text-lg-start">
            <InView
              as="div"
              onChange={(inView) =>
                !contentStatus ? setContentStatus(inView) : null
              }
            >
              <h3
                className={`text-center text-lg-start text-xl-start ${
                  contentStatus ? "start-animate-lr" : ""
                }`}
              >
                Creating innovative solutions drive business growth through cutting-edge B2B and SaaS products
              </h3>
              <p
                className={`banner-content-description text-center text-lg-start text-xl-start ${
                  contentStatus ? "start-animate-lr" : ""
                }`}
              >
                We tailor our approach to meet the unique needs of our clients and use the latest technology to 
                deliver products that are user friendly, efficient, and effective. Our goal is to empower businesses
                with the tools they need to succeed.
              </p>
              {/* <Button
                className={`start-project-button ${
                  contentStatus ? "start-animate-lr" : ""
                }`}
                outline={false}
              >
                Start Project
              </Button> */}
            </InView>
          </div>
          <div className="col-12 col-lg-6 card-container">
            <div className="d-grid grid-row-content">
              <div className={`card-1 ${mobileAppStatus ? "start-animate" : ""}`}>
                <InView
                  as="div"
                  onChange={(inView) =>
                    !mobileAppStatus ? setMobileAppStatus(inView) : null
                  }
                >
                  <Card onClick={handleQRCodeClick} className="qr-code-card">
                    <CardBody>
                      {QRCodeImage}
                      <CardTitle tag="h4">
                        <img src={QRCodeLogo} className="qr-code-logo" alt="qr-logo" />  
                      </CardTitle>
                      {coneIcon}
                    </CardBody>
                  </Card>
                </InView>
              </div>
              <div className={`card-2 ${webAppStatus ? "start-animate" : ""}`}>
                {lineConnectIcon}
                <InView
                  as="div"
                  onChange={(inView) =>
                    !webAppStatus ? setWebAppStatus(inView) : null
                  }
                >
                  <Card onClick={handleBillSuiteClick} className="bill-suite-card">
                    <CardBody>
                      {billIconImage}
                      {billSuiteImage}
                      <CardTitle tag="h4">Bill Suite AI</CardTitle>
                      {webAppCircleIcon}
                    </CardBody>
                  </Card>
                </InView>
              </div>
            </div>
          </div>
        </div>
        <img src={ProductBg} className="product-bg" alt="background" />
        {symbolGroupImage}
        {yellowDotsGroup}
      </section>
      <section id="banner" className="banner-component-container container-fluid">
        {bannerBg}
        {treeViewShape}
        {bannerTreeView}
        <div className="row">
          <div className="col-12 col-lg-6 header-title text-center text-lg-start">
            <InView
              as="div"
              onChange={(inView) =>
                !contentStatus ? setContentStatus(inView) : null
              }
            >
              <h1
                className={`text-center text-lg-start text-xl-start ${
                  contentStatus ? "start-animate-lr" : ""
                }`}
              >
                Labo will help your digital footprint with us
              </h1>
              <p
                className={`banner-content-description text-center text-lg-start text-xl-start ${
                  contentStatus ? "start-animate-lr" : ""
                }`}
              >
                Using our expertise in app development, we will help you create
                practical and seamless experience across devices to give the best
                of technology for your business.
              </p>
              {/* <Button
                className={`start-project-button ${
                  contentStatus ? "start-animate-lr" : ""
                }`}
                outline={false}
              >
                Start Project
              </Button> */}
            </InView>
          </div>
          <div className="col-12 col-lg-6 card-container">
            <div className="d-grid grid-row-content">
              <div className={`card-1 ${mobileAppStatus ? "start-animate" : ""}`}>
                <InView
                  as="div"
                  onChange={(inView) =>
                    !mobileAppStatus ? setMobileAppStatus(inView) : null
                  }
                >
                  <Card>
                    <CardBody>
                      {goalIcon}
                      <CardTitle tag="h4">Our Goal</CardTitle>
                      <CardText>
                        Our goal is to drive the shift towards a digital economy,
                        by providing businesses with innovative digital solutions.
                      </CardText>
                      {coneIcon}
                    </CardBody>
                  </Card>
                </InView>
              </div>
              <div className={`card-2 ${webAppStatus ? "start-animate" : ""}`}>
                {lineConnectIcon}
                <InView
                  as="div"
                  onChange={(inView) =>
                    !webAppStatus ? setWebAppStatus(inView) : null
                  }
                >
                  <Card>
                    <CardBody>
                      {webAppCardIcon}
                      <CardTitle tag="h4">Success</CardTitle>
                      <CardText>
                        For us, success is to provide our clients with
                        cost-effective technology solutions that solve business
                        challenges and create greater convenience for society.
                      </CardText>
                      {webAppCircleIcon}
                      {doubleArrow}
                    </CardBody>
                  </Card>
                </InView>
              </div>
              <div className={`card-3 ${transformStatus ? "start-animate" : ""}`}>
                {dotsImageTransform}
                <InView
                  as="div"
                  onChange={(inView) =>
                    !transformStatus ? setTransformStatus(inView) : null
                  }
                >
                  <Card>
                    <CardBody>
                      {transformCardIcon}
                      <CardTitle tag="h4">Transform</CardTitle>
                      <CardText>
                        Digital transforming is the key in creating new or
                        modifying existing business processes, culture, and
                        customer experiences to meet changing business and market{" "}
                        <span className="requirements-text">needs</span>
                      </CardText>
                    </CardBody>
                  </Card>
                </InView>
              </div>
            </div>
          </div>
        </div>
        {symbolGroupImage}
        {yellowDotsGroup}
        {shapeImage}
        {lineShape}
        {lineShapeSec}
      </section>
    </Carousel>
  );
};

export default BannerComponent;
