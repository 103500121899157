import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import testServiceBg from "../../assets/images/test-services.svg";

const TestServicesComponent = (props) => {
  return (
    <section id="test-services-component" className="test-services-container">
      <TitleRenderer title="Test Services" />
      <h4 className="text-center">What we Test?</h4>
      <p className="service-desc text-center">
        If it's software, websites, or mobile apps, we test it. The breadth of
        our outsourced software testing services is second to none. Our team has
        expertise you can count on to test everything from websites and mobile
        apps to 3D Printing.
      </p>
      <div className="test-services-content">
        <img
          src={testServiceBg}
          className="test-service-bg float-end"
          alt="test-service-background"
        />
        <p>
          <h5>Mobile Apps</h5>
          <span className="spacing"></span>
          Mobile App Testing Ensures Your Application Performs Exactly as
          Expected.
          <br />
          <span className="spacing"></span>
          Our moto is to offer ready-to-go, right-the-first-time mobile
          solutions to our customers. Nowadays competing mobile technologies
          that are changing rapidly, creating a huge variability in testing and
          resource requirements. Labo offers an array of mobile app testing
          services, combining test automation with manual testing to help your
          mobile apps excel in the market.
          <br />
          <span className="spacing"></span>
          Our test engineers have the experience to test applications developed
          for different OS and systems. We use a combo of manual and automated
          testing to fast-track the mobile app testing process, knowing they are
          bug-free and are top-notch.
          <br />
          <span className="spacing"></span>
          <span className="spacing"></span>
          <h5>Websites & Web Apps</h5>
          <span className="spacing"></span>
          We offer testing expertise combined with in-depth knowledge of
          Internet technologies. We bring you the best solution to meet your
          quality goals. Our test team flexes to meet your day-to-day needs. We
          guarantee our work and all of our services are provided onshore.
          <br />
          <span className="spacing"></span>
          The test plan covers complete site, including all of its sub-pages,
          back-end operation, and third-party service links. We thoroughly test
          the website or web app functionality, compatibility with a wide
          variety of operating systems and browsers, and verify its content.
          <br />
          <span className="spacing"></span>
          <span className="spacing"></span>
          <h5>REST API Testing</h5>
          <span className="spacing"></span>
          REST API automated testing is critical for product quality and CI/CD
          processes. Unlike GUI tests, REST API tests can cope with short
          release cycles and frequent changes — without breaking the test
          outputs. REST API testing is carried away to verify if the API reaches
          up to the expectations with respect to reliability, functionality,
          security and performance of the application.
          <br />
          <span className="spacing"></span>
          At Labo Next, we offer a wide range of top-notch REST API Testing
          services to ensure a highly robust software application for your
          business. Our well-trained and highly experienced professional testers
          make sure that with our industry-standard API testing, your
          application meets the needs and requirements for your target audience.
        </p>
      </div>
    </section>
  );
};

export default TestServicesComponent;
