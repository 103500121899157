export const questionList = [
  {
    id: 98,
    questionText: "I want to build",
    isHide: true,
  },
  {
    id: 99,
    questionText: "Platform Type",
    isHide: true,
  },
  {
    id: 100,
    questionText: "Choose the industry domain of your app",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Hardware, IoT",
        value: "hardware and iot",
      },
      {
        label: "Ecommerce / Retail",
        value: "ecommerce or retail",
      },
      {
        label: "Booking",
        value: "booking",
      },
      {
        label: "Education",
        value: "education",
      },
      {
        label: "Social networking",
        value: "social networking",
      },
      {
        label: "Events",
        value: "events",
      },
      {
        label: "Tools/Utilities",
        value: "tools or utilities",
      },
      {
        label: "Medtech / Healthcare",
        value: "medtech or healthcare",
      },
      {
        label: "Artificial Intelligence",
        value: "artificial intelligence",
      },
      {
        label: "Maps & Navigation",
        value: "maps and navigation",
      },
      {
        label: "Lifestyle & Hobby",
        value: "lifestyle and hobby",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
  {
    id: 101,
    questionText: "What is the design style of your app?",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Basic",
        value: "basic",
      },
      {
        label: "Polished",
        value: "polished",
      },
      {
        label: "I already have the design",
        value: "already have",
      },
    ],
  },
  {
    id: 102,
    questionText: "What devices should your app be compatible with?",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Smartphones",
        value: "smartphones",
      },
      {
        label: "Tablets",
        value: "tablets",
      },
      {
        label: "Smartwatch",
        value: "smartwatch",
      },
      {
        label: "Desktop",
        value: "desktop",
      },
      {
        label: "All of the above",
        value: "smartphones, tablets, smartwatch, desktop",
      },
    ],
  },
  {
    id: 103,
    questionText:
      "Does your app contain a user login feature? If yes, specify the login method",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Via email",
        value: "email",
      },
      {
        label: "Via social network",
        value: "social network",
      },
      {
        label: "No Login required",
        value: "no login",
      },
    ],
  },
  {
    id: 104,
    questionText: "Do type of built-in payment feature does your app require?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Payment Processing via Payment Gateway",
        value: "payment through gateway",
      },
      {
        label: "In-app Purchasing through Wallet",
        value: "in app purchase",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 105,
    questionText: "Specify the type of geolocation feature?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Simple",
        value: "simple",
      },
      {
        label: "Advanced",
        value: "advanced",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 106,
    questionText: "Will you have a newsfeed?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Simple",
        value: "simple",
      },
      {
        label: "Customized newsfeed",
        value: "customized newsfeed",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 107,
    questionText: "Will your app contain any list of items or goods?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Yes – simple",
        value: "yes and simple",
      },
      {
        label: "Yes – Advanced",
        value: "yes and advanced",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 108,
    questionText: "Do you need a communications feature?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Simple(user to user)",
        value: "simple and user to user",
      },
      {
        label: "Advanced (Group chat, postponed delivery, etc.)",
        value: "advanced (Group chat, postponed delivery)",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 109,
    questionText: "Any event-planning tools?",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Calendar",
        value: "calendar",
      },
      {
        label: "Tickets Management",
        value: "tickets management",
      },
      {
        label: "Appointment’s scheduling",
        value: "appointment's scheduling",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 110,
    questionText: "Choose the type of content.",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Photo",
        value: "photo",
      },
      {
        label: "Video/Audio",
        value: "video or audio",
      },
      {
        label: "Streaming",
        value: "streaming",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 111,
    questionText: "How will you engage with your users?",
    selectionType: "multiple",
    questionOptions: [
      {
        label: "Ratings",
        value: "ratings",
      },
      {
        label: "Polls/Forms",
        value: "polls or forms",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 112,
    questionText: "Do you need push notifications?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Basic",
        value: "basic",
      },
      {
        label: "Customized",
        value: "customized",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 113,
    questionText: "What kind of data analytics will you perform and present?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Basic",
        value: "basic",
      },
      {
        label: "Advanced",
        value: "advanced",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
  {
    id: 114,
    questionText: "Do you need an admin panel?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: 115,
    questionText: "Need a user managing tool?",
    selectionType: "single",
    questionOptions: [
      {
        label: "Basic",
        value: "basic",
      },
      {
        label: "Customized",
        value: "customized",
      },
      {
        label: "None",
        value: "none",
      },
    ],
  },
];
