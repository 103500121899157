import {
  cloudsGroupImage,
  missionImage,
  mountainImage,
  visionImage,
  coneShapedIcon,
  halfCircleIcon,
  visionBg,
  dotsGroupAboutUs,
} from "../../assets/icons/iconCollection";
import { Card, CardText, CardBody } from "reactstrap";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";

const MissionVisionComponent = (props) => {
  const missionSvg = missionImage({
    className: "mission-image",
  });

  const visionSvg = visionImage({
    className: "vision-icon",
  });

  const cloudGroupSvg = cloudsGroupImage({
    className: "cloud-group-image",
  });

  const mountainSvg = mountainImage({
    className: "mountain-image",
  });

  const coneIcon = coneShapedIcon({
    className: "cone-icon-vision",
  });

  const halfCircleEllipsis = halfCircleIcon({
    className: "circle-half-icon",
  });

  const visionBgSvg = visionBg({
    className: "vision-bg",
  });

  const dotsGroupSvg = dotsGroupAboutUs({
    className: "dots-group",
  });

  return (
    <section id="mission-vision" className="mission-vision-component">
      <TitleRenderer title="Our Mission" className="mission-title" />
      <div className="row position-relative">
        <p className="mission-sub-title text-center">
          We think twice code once
        </p>
        <div className="col-12 col-xl-12">
          <div className="row position-relative">
            <div className="col-12 col-xl-7">
              <div className="mission-content-container">
                <p className="mission-desc">
                  Our mission is to be the partner to our clients, to enable
                  them onto a road of success. We deliver trustworthy and
                  innovative solutions to our customers to help them create a
                  better tomorrow
                </p>
              </div>
            </div>
            <div className="col-12 col-xl-5">
              {missionSvg}
              {cloudGroupSvg}
              {mountainSvg}
            </div>
            {dotsGroupSvg}
          </div>
        </div>
        <div id="vision-section" className="col-12 col-xl-12 vision-section">
          <TitleRenderer title="Our Vision" className="vision-title" />
          <div className="vision-content-container">
            <div className="row">
              <div className="col-12 col-xl-6">{visionSvg}</div>
              <div className="col-12 col-xl-6">
                <div className="description-container">
                  {visionBgSvg}
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Card className="card-1">
                        <CardBody>
                          <CardText className="arrow">
                            We believe every day is an opportunity to further
                            perfect our strategy and spur you on to achieve your
                            organizational goals. Our vision is to make you
                            succeed in yours.
                          </CardText>
                          {coneIcon}
                        </CardBody>
                      </Card>
                      <Card className="card-3 vision-card-last">
                        <CardBody>
                          <CardText className="arrow">
                            To be a market leader in the Technology front by
                            providing the best Labo catering to all our customer
                            needs.{" "}
                          </CardText>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-12 col-md-6">
                      <Card className="card-2">
                        <CardBody>
                          <CardText className="arrow">
                            We consistently work on our process that provides
                            informative, user-friendly and effective strategies
                            to the company that will allow them in realising
                            their goals. This process is tailor made for each
                            client to meet the specific needs of small, medium
                            and large size business.{" "}
                          </CardText>
                          {halfCircleEllipsis}
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVisionComponent;
