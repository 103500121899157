import {
  computerIcon,
  digitalIcon,
  logoDesignImage,
  monitoringImage,
  networkIcon,
  serviceAppImage,
  serviceAppInventory,
} from "../../assets/icons/iconCollection";
import kgImage from "../../assets/images/kg.svg";
import rajImage from "../../assets/images/raj.svg";
import gopiImage from "../../assets/images/gopi.svg";
import karthiImage from "../../assets/images/karthi.svg";
import iyappanImage from "../../assets/images/iyappan.svg";
import sathishImage from "../../assets/images/sathish.svg";

const networkImage = networkIcon({
  className: "network-icon",
});

const digitalImage = digitalIcon({
  className: "digital-icon",
});

const computerImage = computerIcon({
  className: "computer-icon",
});

const serviceLogoDesign = logoDesignImage({
  className: "logo-design",
});

const serviceAppIcon = serviceAppImage({
  className: "service-app",
});

const serviceInventoryIcon = serviceAppInventory({
  className: "service-inventory",
});

const monitoringIcon = monitoringImage({
  className: "service-monitor",
});

export const serviceListData = () => {
  return [
    {
      title: "",
      icon: digitalImage,
      description: `Digital is the way forward for any 
        business whether small or big. You want a tailor-made app for the customer to use at 
        home or in your business location we can do it for you. In the most reasonable and 
        efficient way possible.`,
    },
    {
      title: "",
      icon: computerImage,
      description: `Do not get confused on how to set up your system infrastructure
        and network the most efficient way. No blind spots in Wi-Fi. we
        will set it up for you so that you need not have sleepless
        nights.`,
    },
    {
      title: "",
      icon: serviceLogoDesign,
      description: `In this cluttered world of business, everyone is trying to 
        catch the customers’ eye, we will help you in designing your Logo, your 
        brochure.  You call and we are there.`,
    },
    {
      title: "",
      icon: serviceAppIcon,
      description: `You want an app which can make inventory control in your 
        shop, showroom … child play we will do it for you`,
    },
    {
      title: "",
      icon: serviceInventoryIcon,
      description: `You want a billing software/ app that will do billing as also keep inventory, we are there.`,
    },
    {
      title: "",
      icon: monitoringIcon,
      description: `The systems have been done but you do not want it to fail in production. 
        We will test it for you. We will create the use cases and the suite of corresponding transactions to 
        ensure that your systems run without giving you any headaches.`,
    },
    {
      title: "",
      icon: networkImage,
      description: `Our team can evaluate all your processes, identify the
        bottlenecks, inefficiencies, recommend the solutions to make it
        transparent, predictable and without any surprises.`,
    },
  ];
};

export const teamMembersData = [
  {
    name: "Raj Kumar",
    imgUrl: rajImage,
    designation: "Founder / CEO",
    description:
      "hey can identify a class or category of things, or a single thing, either uniquely, or within a given context.",
  },
  // {
  //   name: "Kumaraguru",
  //   imgUrl: kgImage,
  //   // designation: "Director",
  //   designation: "VP Head of Product",
  //   description: `Professional Frontend developer with a demonstrated history of working in the information technology and services industry. 
  //     Skilled in UI Development, Product Lifecycle Management, Requirements Gathering and Agile Project Management.`,
  // },
  {
    name: "Iyappan",
    imgUrl: iyappanImage,
    designation: "VP Head of Product",
    description:
      "hey can identify a class or category of things, or a single thing, either uniquely, or within a given context.",
  },
  {
    name: "Sathishkumar",
    imgUrl: sathishImage,
    designation: "VP of Product Management",
    description:
      "hey can identify a class or category of things, or a single thing, either uniquely, or within a given context.",
  },
  {
    name: "Karthick",
    imgUrl: karthiImage,
    designation: "Project Manager",
    description:
      "hey can identify a class or category of things, or a single thing, either uniquely, or within a given context.",
  }
  // ,
  // {
  //   name: "Gopi",
  //   imgUrl: gopiImage,
  //   designation: "QA Manager",
  //   description:
  //     "hey can identify a class or category of things, or a single thing, either uniquely, or within a given context.",
  // },
];
