import {
  chooseUsGroupedImage,
  groupedCircleImage,
  questionGroupImage,
  doubleArrowTech,
} from "../../assets/icons/iconCollection";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";

const WhyChooseUsComponent = (props) => {
  const questionGroupSvg = questionGroupImage({
    className: "choose-us-image",
  });

  const chooseUsGroupedSvg = chooseUsGroupedImage({
    className: "choose-us-group",
  });

  const groupedCircleSvg = groupedCircleImage({
    className: "grouped-circle-image",
  });

  const doubleArrow = doubleArrowTech({
    className: "cloud-arrow",
  });

  return (
    <section id="why-choose-us" className="why-choose-us">
      <TitleRenderer title="Why choose us?" className="why-choose-us" />
      {doubleArrow}
      <div className="why-choose-us-content">
        {questionGroupSvg}
        {groupedCircleSvg}
        {chooseUsGroupedSvg}
        <span>
          <p>
            We place out clients at the heart of everything we do. We have a
            large pool of knowledge that can be applied to solve client problems
            and deliver solutions that meet our client's need, expectations as
            well as budget. Our highly skilled team of engineers with excellent
            technical knowledge and experience in the industry can build and
            deliver software with industry standards in the reasonable time
            frame.
          </p>
          <p>
            We create intelligent business solutions to common business problems
            by bringing fresh ideas to the table. The objective behind all of
            our ideas is client success and satisfaction at anytime, anywhere.
            We always believe that passion for codes create extraordinary things
            happen. We deliver what others promise with on-time and affordable
            range.
          </p>
          <p>
            Choosing the right software development company in order to have
            your dreams achieved and success for your internet product can be a
            difficult task. Here we make sure that you have made the best
            decision for your project to start with and shape the product as you
            imagine.
          </p>
          <p>
            Because we make it simple. Clean code implementation with powerful
            testing. We have a great collection of internal frameworks with
            best-in-class infrastructure with a lot of experience in the IT
            software development industry that helps us to deliver solutions
            with superior quality at all times. We have learned how to avoid
            mistakes and repeat success all these years. We don't brag about
            consistency alone. In time, we describe and produce it. Our Products
            are here to notify the same. We also possess a team that is a
            mixture of the right attitude and technical know-how of the latest
            development tools that are currently the Talk of the town in the
            market.
          </p>
          <p>
            We do not follow the criteria or requirements just like that. Right
            from the beginning we have a direct influence on the project. We
            take spontaneous feedback from our clients, their customers, and
            other stakeholders that have an impact on the product. And we take
            your product and its feedback seriously. With this we make sure that
            we all are heading towards the right direction which saves really
            everything that matters.
          </p>
        </span>
      </div>
    </section>
  );
};

export default WhyChooseUsComponent;
