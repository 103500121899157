import { useEffect, useState } from "react";
import "./styles.scss";

const QuestionRenderer = (props) => {
  const [selectedAnswer, setSelectedAnswer] = useState(
    props?.selectedValue || []
  );

  useEffect(() => {
    if(props.isFormSubmit) {
      setSelectedAnswer([]);
    }
  }, [props.isFormSubmit]);

  const handleOptionClick = (data) => {
    let answerData = [...selectedAnswer];
    if (data.value && answerData.indexOf(data.value) > -1) {
      answerData.splice(answerData.indexOf(data.value), 1);
    } else {
      if (props?.selectionType === "multiple") {
        answerData.push(data.value);
      } else {
        answerData[0] = data.value;
      }
    }
    setSelectedAnswer(answerData);
    if (props?.handleAnswerDataChange) {
      props.handleAnswerDataChange(answerData, props.id);
    }
  };

  return (
    <div
      id="question-data"
      className={`question row ${props?.className ? props.className : ""}`}
    >
      <div className="col-12 col-md-4">
        <p className="question-text">{props.questionText}</p>
      </div>
      <div className="col-12 col-md-8">
        {props?.questionOptions && props.questionOptions?.length > 0 ? (
          props.questionOptions.map((data, index) => {
            return (
              <span
                className={`question-option-text ${
                  props.selectionType === "multiple"
                    ? selectedAnswer?.indexOf(data.value) > -1
                      ? "active"
                      : ""
                    : selectedAnswer?.[0] === data.value
                    ? "active"
                    : ""
                }`}
                onClick={() => handleOptionClick(data, index)}
              >
                {data.label}
              </span>
            );
          })
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};

export default QuestionRenderer;
