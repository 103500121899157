import { useState } from "react";
import InView from "react-intersection-observer";
import {
  aboutUsVectorImage,
  creativeIcon,
  designIcon,
  dotsGroupAboutUs,
  ellipsisCircle,
  greenEllipsis,
  transformationIcon,
  triangleVector,
  yellowEllipsis,
  zigZagLineIcon,
} from "../../assets/icons/iconCollection";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";

const AboutUsComponent = (props) => {
  const [contentAnimationStatus, setContentAnimationStatus] = useState(false);

  const aboutUsVectorSvg = aboutUsVectorImage({
    className: "about-us-image",
    width: "92%",
  });

  const creativeSvg = creativeIcon({
    className: "creative-icon",
  });

  const designSvg = designIcon({
    className: "design-icon",
  });

  const transformSvg = transformationIcon({
    className: "transform-icon",
  });

  const triangleVectorSvg = triangleVector({
    className: "triangle",
  });

  const zigZagLineShapeSvg = zigZagLineIcon({
    className: "line-shape-custom",
  });

  const circleEllipsis = greenEllipsis({
    className: "ellipsis-circle-green",
  });

  const circleEllipsisSec = greenEllipsis({
    className: "ellipsis-circle-green-1",
  });

  const circleEllipsisTer = greenEllipsis({
    width: "8px",
    height: "8px",
    className: "ellipsis-circle-green-2",
  });

  const circleWithEllipsis = ellipsisCircle({
    className: "ellipsis-circle",
  });

  const yellowEllipsisImage = yellowEllipsis({
    className: "yellow-ellipsis",
  });

  const aboutUsGroupedDotsImage = dotsGroupAboutUs({
    className: "dots-group",
  });

  return (
    <section id="about-us" className="about-us-component">
      <TitleRenderer title="What we do?" className="about-us-title" />
      <InView
        as="div"
        onChange={(inView) =>
          !contentAnimationStatus ? setContentAnimationStatus(inView) : null
        }
      >
        <h1
          className={`sub-title text-center ${
            contentAnimationStatus ? "start-animate-ud" : ""
          }`}
        >
          We convert your <span className="ideas">Ideas</span> into Apps
        </h1>
        <p
          className={`about-us-desc text-center ${
            contentAnimationStatus ? "start-animate-ud" : ""
          }`}
        >
          Labo helps you to convert your ideas into the best viable solutions
          for your business. We provide you with the technical expertise and
          market knowledge required to create apps and websites to run business
          with ease.
        </p>
      </InView>
      <div className="row desc-list-content">
        <div className="col-12 col-lg-6 position-relative">
          {triangleVectorSvg}
          {zigZagLineShapeSvg}
          {aboutUsVectorSvg}
          {circleEllipsis}
          {yellowEllipsisImage}
          {circleWithEllipsis}
        </div>
        <div className="col-12 col-lg-6 position-relative">
          {circleEllipsisSec}
          {circleEllipsisTer}
          <ul className="about-us-list">
            <li>
              <div className="list-content">
                {designSvg}
                <div>
                  <h6>Design</h6>
                  <p>
                    A design is a detailed plan for the construction of a system
                    or a process, the result of that specification in the form
                    of a prototype, product or process. The applications we
                    create will have visual symmetry, functional stability, and
                    consistency.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="list-content">
                {creativeSvg}
                <div>
                  <h6>Innovate</h6>
                  <p>
                    Innovation is achieved by putting knowledge to work in
                    creating the most feasible solutions to solve customer
                    problems.
                  </p>
                </div>
              </div>
            </li>
          </ul>
          {aboutUsGroupedDotsImage}
        </div>
      </div>
    </section>
  );
};

export default AboutUsComponent;
