import { connectedDotsImage } from "../../assets/icons/iconCollection";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import veeImage from "../../assets/images/vee-app.svg";
import rectDotsGroup from "../../assets/images/rect-dots-group.svg";
import { veeAppLink } from "../../common/constants";

const SuccessStoriesComponent = (props) => {
  const dotsImage = connectedDotsImage({
    className: "dots-image",
  });

  const handleVeeAppClick = () => {
    window.open(veeAppLink);
  };

  return (
    <section className="success-stories-component-container">
      <TitleRenderer title="Success Stories" />
      <div
        onClick={handleVeeAppClick}
        className="vee-content d-flex align-items-center justify-content-center"
      >
        <div className="d-flex align-items-end flex-column">
          <img
            src={rectDotsGroup}
            width="41px"
            alt="rect-group"
            className="rect-group"
          />
          <img
            src={veeImage}
            width="216px"
            alt="vee-app"
            className="vee-image"
          />
        </div>
        <div>
          {dotsImage}
          <p>
            Our success till now <br />
            <span>Vee biom</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default SuccessStoriesComponent;
