import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { HeaderComponent } from "./components/Header/HeaderComponent";
import { HomeComponent } from "./components/Home/HomeComponent";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/theme.scss";
import FooterComponent from "./components/Footer/FooterComponent";
import CostCalculator from "./components/CostCalculator/ProjectCostCalculator";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ReactGA from "react-ga";

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1200,
  },
};
ReactGA.initialize("G-62QQ4N32J1");

const App = () => {
  const [themeValue, setThemeValue] = useState("default");
  const [activeHashLocation, setActiveLocation] = useState("home");

  ReactGA.pageview(window.location.pathname + window.location.search);

  const handleHashChange = (value) => {
    const selectedEl = document.getElementById(value);
    const bodyEl = document.body;

    if (selectedEl) {
      bodyEl.scrollTop = selectedEl.offsetTop - 100;
      setActiveLocation(value);
    } else {
      setTimeout(() => {
        const elToView = document.getElementById(value);

        if(elToView) {
          bodyEl.scrollTop = elToView.offsetTop - 100;
        }
        setActiveLocation(value);
      }, 100);
    }
  };

  const updateLocation = (value) => {
    setActiveLocation(value);
  };

  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className={`app-container labosoft ${themeValue}-theme`}>
          <HeaderComponent
            currentTheme={themeValue}
            setTheme={setThemeValue}
            handleHashChange={handleHashChange}
            hashLocation={activeHashLocation}
          />
          <Routes>
            <Route
              path="/"
              element={
                <HomeComponent
                  currentTheme={themeValue}
                  setTheme={setThemeValue}
                  handleHashChange={handleHashChange}
                  updateLocation={updateLocation}
                />
              }
            />
            <Route
              path="/cost/calculator"
              element={
                <CostCalculator
                  currentTheme={themeValue}
                  handleHashChange={handleHashChange}
                  setTheme={setThemeValue}
                />
              }
            />
          </Routes>
          <FooterComponent />
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
