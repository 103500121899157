import { useEffect, useState } from "react";
import { pentagonShapeIcon } from "../../assets/icons/iconCollection";
import "./style.scss";

const XS_BREAKPOINT = 992;
const LARGE_DEVICE = "large";
const SMALL_DEVICE = "small";

export const ZigZagRenderer = (props) => {
  const [deviceName, setDeviceName] = useState(""); //Possible values: [large, small]

  const setDeviceBasedOnWidth = () => {
    const { innerWidth: width } = window;
    let deviceValue = width < XS_BREAKPOINT ? SMALL_DEVICE : LARGE_DEVICE;

    if (deviceName !== deviceValue) {
      setDeviceName(deviceValue);
    }
  };

  const pentagonImage = pentagonShapeIcon({
    className: "arrow-image",
  });

  useEffect(() => {
    setDeviceBasedOnWidth();
    document.addEventListener("resize", setDeviceBasedOnWidth);

    return () => {
      document.removeEventListener("resize", setDeviceBasedOnWidth);
    };
  }, [deviceName]);

  return (
    <div className="zig-zag-renderer">
      {props.contentList.map((data, index) => {
        return (
          <div
            key={`content-${index}`}
            className={`content-${index} col-12 col-lg-6  ${
              deviceName === SMALL_DEVICE
                ? ""
                : index % 2 === 0
                ? "clear-right"
                : "float-end"
            }`}
          >
            {index % 2 !== 0 || deviceName === SMALL_DEVICE ? (
              <span className="icon-container">
                {pentagonImage}
                {data?.icon}
              </span>
            ) : null}
            <div>
              <h5>{data.title}</h5>
              <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            </div>
            <span>
              {index % 2 === 0 && deviceName !== SMALL_DEVICE ? (
                <span className="icon-container">
                  {pentagonImage}
                  {data?.icon}
                </span>
              ) : null}
            </span>
          </div>
        );
      })}
    </div>
  );
};
