import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navbar,
  NavItem,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  menuToggle,
  customLineShaped,
} from "../../assets/icons/iconCollection";
import "./header.scss";
import { Link } from "react-router-dom";

export class HeaderComponent extends React.Component {
  constructor(props) {
    super();
    this.siteURL = {
      laboNeftStaticQRLink: 'https://www.qrcode.laboneft.com/index.html',
      laboNeftBillSuiteLink: 'https://billsuite.laboneft.com/'
    };
    let menuToggleIcon = menuToggle({
      width: "24px",
      height: "24px",
      className: "menu-toggle-default",
    });

    let customLineShapedImage = customLineShaped({
      className: "active-nav-icon",
    });

    this.state = {
      isOpen: false,
      menuToggleIconSvg: menuToggleIcon,
      customLineIcon: customLineShapedImage,
      activeHashLocation: "home",
    };
  }

  setIsOpen = (value) => {
    this.setState({ isOpen: value });
  };

  setHashLocation = (value) => {
    const { handleHashChange } = this.props;

    if (handleHashChange) {
      handleHashChange(value);
    }
  };

  onMouseEnter= ()=> {
    this.setState({ dropdownOpen1: true });
  }

  onMouseLeave= ()=> {
    this.setState({ dropdownOpen1: false });
  }
  toggle1= ()=> {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }
  openSite= (key) => {
    window.open(this.siteURL[key], "_blank")
  };
  render() {
    const { isOpen, menuToggleIconSvg, customLineIcon } = this.state;
    const { hashLocation } = this.props;

    return (
      <header className="header-container">
        <Navbar sticky="top" className="content-container" expand="lg">
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="menu-toggle-container d-block d-lg-none"
              onClick={() => {
                this.setIsOpen(!isOpen);
              }}
            >
              <Button outline={false}>{menuToggleIconSvg}</Button>
            </div>
            <div className="logo-container">
              <NavLink
                tag={Link}
                to="/#home"
                onClick={() => this.setHashLocation("home")}
              >
                <NavbarBrand>
                  <span className="logo-image"></span>
                </NavbarBrand>
              </NavLink>
            </div>
          </div>
          <Collapse className="" isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  active={hashLocation === "home"}
                  tag={Link}
                  to="/#home"
                  onClick={() => this.setHashLocation("home")}
                >
                  <span>Home</span>
                  {hashLocation === "home" && customLineIcon}
                </NavLink>
              </NavItem>

              <NavItem >
              <NavLink>
            <Dropdown
              className="labo-products-dropdown"
              onMouseOver={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              isOpen={this.state.dropdownOpen1}
              toggle={this.toggle1}
            >
          <DropdownToggle className="headerBtn btn" >
            <span className="txt">Products</span>
          </DropdownToggle>
          
          <DropdownMenu>
            <DropdownItem>
              QR Code
              <div className="product-qr-code-list">
                <div onClick={() => this.openSite('laboNeftStaticQRLink')}>Static QR Code</div>
                <div className="disabled">Dynamic QR Code</div>
              </div>
            </DropdownItem>
            <DropdownItem onClick={() => this.openSite('laboNeftBillSuiteLink')}>
              Bill Suite AI
            </DropdownItem>
          </DropdownMenu>
          &nbsp;&nbsp;&nbsp;
        </Dropdown>
                </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink
                  active={hashLocation === "about-us"}
                  onClick={() => this.setHashLocation("about-us")}
                  tag={Link}
                  to="/#about-us"
                >
                  What we do?
                  {hashLocation === "about-us" && customLineIcon}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={hashLocation === "services"}
                  onClick={() => this.setHashLocation("services")}
                  tag={Link}
                  to="/#services"
                >
                  Offerings
                  {hashLocation === "services" && customLineIcon}
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  active={activeHashLocation === "solutions"}
                  onClick={() => this.setHashLocation("solutions")}
                  href="#solutions"
                >
                  Solutions
                  {activeHashLocation === "solutions" && customLineIcon}
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  active={hashLocation === "technology"}
                  onClick={() => this.setHashLocation("technology")}
                  tag={Link}
                  to="/#technology"
                >
                  Technical know how
                  {hashLocation === "technology" && customLineIcon}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={hashLocation === "contact"}
                  onClick={() => this.setHashLocation("contact")}
                  tag={Link}
                  to="/#contact"
                >
                  Contact
                  {hashLocation === "contact" && customLineIcon}
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          {!window.location.pathname?.includes("/cost/calculator") && (
            <div className="cost-calculator-button-container">
              <Link to="/cost/calculator">
                <Button
                  className="cost-calculator-button"
                  onClick={this.handleButtonClick}
                  outline={false}
                >
                  Project Cost Calculator
                </Button>
              </Link>
            </div>
          )}
        </Navbar>
      </header>
    );
  }
}
