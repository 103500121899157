import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import uiBg from "../../assets/images/uiux.svg";
import uxBg from "../../assets/images/ux.svg";

const UIUXComponent = (props) => {
  return (
    <section id="ui-ux-component" className="ui-ux-container">
      <TitleRenderer title="UI/UX Design" />
      <p className="desc">
        We are a UI/UX design company that emotes and innovates user experience
        by incorporating research and user-centric innovations.
      </p>
      <div className="row user-interface-section">
        <div className="col-12 col-md-5">
          <h4>User Interface</h4>
          <div className="content-ui">
            <p>
              Interfaces are the face of all human interactions. At Yellow
              Slice, we strive towards making interactions, gestures and
              experiences more usable. Visual language plays an important role
              to deliver human-centric designs.
            </p>
            <p>
              We provide the interface design solution that meets business goals
              and fulfil user needs. Duration of the project can vary
              considerably depending on required number of screens & footprints.
            </p>
            <p>
              Deliverable includes required number of screens across footprints
              delivered as JPEGs or HTMLs.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-7">
          <img src={uiBg} className="ui-bg" alt="ui-background" />
        </div>
      </div>
      <div className="row user-experience-section">
        <div className="col-12 col-md-5 order-2 order-md-1">
          <img src={uxBg} className="ux-bg" alt="ux-background" />
        </div>
        <div className="col-12 col-md-7 order-1 order-md-2">
          <h4>User Experience</h4>
          <div className="content-ux">
            <p>
              “UX design projects deliver the best outcomes when they follow a
              systematic process that ensures that the design is informed,
              iterated and validated. “
            </p>
            <p>
              Ideally, done as a four-step process: Problem definition,
              research, design and testing.
            </p>
            <p>
              Design is created with a scientific basis grounded in cognitive
              psychology and behavioural science.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UIUXComponent;
