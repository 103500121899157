import "./footer.scss";
import {
  linkedInIcon,
  instagramIcon,
  twitterIcon,
  fbIcon,
} from "../../assets/icons/iconCollection";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  facebookLink,
  instagramLink,
  linkedInLink,
  twitterLink,
} from "../../common/constants";

const FooterComponent = (props) => {
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const linkedInImage = linkedInIcon({
    className: "linked-in",
  });

  const instagramImage = instagramIcon({
    className: "instagram",
  });

  const fbImage = fbIcon({
    className: "fb",
  });

  const twitterImage = twitterIcon({
    className: "twitter",
  });

  const onPrivacyPolicyClick = () => {
    setPrivacyModalOpen(true);
  };

  const onTermsAndConditionClick = () => {
    setTermsModalOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyModalOpen(false);
  };

  const handleTermsClose = () => {
    setTermsModalOpen(false);
  };

  return (
    <>
      <footer className="footer-container">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-3">
            <h6>About Us</h6>
            <ul>
              <li>
                <a href="#about-us">Turn your Ideas into Apps</a>
              </li>
              <li>
                <a href="#mission-vision">Our Mission</a>
              </li>
              <li>
                <a href="#vision-section">Our Vision</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <h6>Offering</h6>
            <ul>
              <li>
                <a>What we offer?</a>
                <ul>
                  <li>
                    <a href="#android-app">Android Apps</a>
                  </li>
                  <li>
                    <a href="#ios-app">iOS Apps</a>
                  </li>
                  <li>
                    <a href="#web-app-component-container">Web development</a>
                  </li>
                  <li>
                    <a href="#ui-ux-component">UI/UX DESIGN</a>
                  </li>
                  <li>
                    <a href="#solutions">Testing</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <h6>Technical know how</h6>
            <ul>
              <li>
                <a href="#technology">Databases</a>
              </li>
              <li>
                <a href="#technology">Development tools</a>
              </li>
              <li>
                <a href="#technology">Web Tool</a>
              </li>
              <li>
                <a href="#technology">ORM tools</a>
              </li>
              <li>
                <a href="#technology">Cloud</a>
              </li>
              <li>
                <a href="#technology">Open Framwork</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <h6>Follow Us</h6>
            <ul>
              <li>
                <a href={linkedInLink} target="_blank">
                  {linkedInImage} Linkedin
                </a>
              </li>
              <li>
                <a href={instagramLink} target="_blank">
                  {instagramImage} Instagram
                </a>
              </li>
              <li>
                <a href={twitterLink} target="_blank">
                  {twitterImage} Twitter
                </a>
              </li>
              <li>
                <a href={facebookLink} target="_blank">
                  {fbImage} Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="link">
          <a onClick={onTermsAndConditionClick}>Terms & Conditions</a> |&nbsp;
          <a onClick={onPrivacyPolicyClick}>Privacy Policy</a>
        </p>
        <p className="copyright-content">
          Copyright © 2022 laboneft. All right reserved
        </p>
      </footer>
      <Modal
        isOpen={privacyModalOpen}
        className="privacy-content-modal"
        centered={true}
      >
        <ModalHeader>Privacy Policy</ModalHeader>
        <ModalBody>
          <h6>Privacy policies to collect your personal information</h6>
          <p>
            We may collect your personal information here to provide you with
            the products, services and information that you have requested. To
            manage and maintain our relationship with you, including responding
            to an inquiry, question or comment made by you and providing you
            with other customer support
          </p>
          <br />
          <h6>What personal data will we process?</h6>
          <p>
            We will process contact information that is collected from you
            during the course of business activities e.g., through interactions
            with our company at events, by telephone, via e-mail, through our
            website, etc. We will also process Online behaviour and experience
            data collected via our websites.
          </p>
          <br />
          <h6>How long we keep it?</h6>
          <p>
            Your personal information will be retained only for so long as
            reasonably necessary for the purposes set out in this Privacy policy
            in accordance with applicable laws, including for the purposes of
            satisfying any legal, regulatory, accounting or reporting
            requirements. The Privacy policy applies to the collection and use
            of personal information relating to clients, vendors, business
            partners, visitors, marketing contacts and other external
            individuals with whom we interact, primarily in an offline context
            and applies to the collection and use of personal information
            relating to candidates who seek employment with us.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="privacy-cancel-button"
            onClick={handlePrivacyClose}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={termsModalOpen}
        className="terms-content-modal"
        centered={true}
      >
        <ModalHeader>Terms & Conditions</ModalHeader>
        <ModalBody>
          <p>
            Labo brings dreams closer to technology. We create mobile
            applications, web applications, hybrid application, UI/UX design,
            security and software maintenance. We request the users of this
            website to go through the terms and conditions and abide by the
            rules.
            <br />
            Throughout the site, the terms “we”, “us” and “our” refer to Labo.
            <br />
            By visiting our site and/ or purchasing something from us, you
            engage in our “Service” and agree to be bound by the following terms
            and conditions. These Terms of Service apply to all users of the
            site, including without limitation users who are browsers, vendors,
            customers, merchants, and/ or contributors of content.
            <br />
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any services.
          </p>
          <h6>Contents of this website:</h6>
          <p>
            Labo shall make its best efforts to ensure that the information
            displayed on this website is accurate and up-to-date. We use
            necessary cookies to make our site work and analytics cookies to
            help us improve it. We will not collect any personal identifiable
            information apart from name, company name and requirements unless
            you enable us to do so. In order to ensure safety of the website to
            us and for the users, we may monitor network traffic to identify
            unauthorized attempts to change information or cause damage to the
            site. Unauthorized attempts to modify, alter, deface or corrupt any
            information stored on this site or this system, to defeat or
            circumvent security features are prohibited and may result in
            criminal prosecution.
          </p>
          <h6>Disclaimer of Liability:</h6>
          <p>
            Labo shall not be held liable neither for the results of any factor
            beyond their control nor for any eventual loss or damage to the
            technical environment of the website users, including, but not
            limited to, hardware, software, or any other equipment used to
            access this website or use the services and/or the information
            herein.
          </p>
          <h6>Copyright:</h6>
          <p>
            No part of this website may be reproduced in any form, by electronic
            or any other means, without written permission from Labo.
            <br />
            All product and company names mentioned herein are of the respective
            owners. No user may copy, modify, publish, transmit, transfer or
            sell, reproduce, create derivative works from, distribute, display,
            or in any way exploit any of the content, in whole or in part.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="terms-cancel-buttons" onClick={handleTermsClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FooterComponent;
