import { useState } from "react";
import InView from "react-intersection-observer";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { TitleRenderer } from "../../widgets/titleRender/TitleRenderer";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { fetchWithPostMethod } from "../../common/fetchApi";
import { useAlert } from "react-alert";

const FORM_ID_DATA = {
  nameField: "contact-name",
  emailField: "contact-email",
  descField: "content-description",
};

const ContactUsComponent = (props) => {
  const [animateStatus, setAnimateStatus] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setFormVisible] = useState(true);

  const alertTemp = useAlert();

  const handleNumberChange = (value) => {
    if (value) {
      setPhoneNumber(value);
    }
  };

  const sendContactInfo = (event) => {
    if (event) {
      event.preventDefault();
    }

    const nameFieldEl = document.getElementById(FORM_ID_DATA.nameField);
    const emailFieldEl = document.getElementById(FORM_ID_DATA.emailField);
    const descFieldEl = document.getElementById(FORM_ID_DATA.descField);

    const nameValue = nameFieldEl?.value || "";
    const emailValue = emailFieldEl?.value || "";
    const descValue = descFieldEl?.value || "";

    if (!(nameValue && emailValue && descValue && phoneNumber)) {
      alertTemp.show("Please fill out all the required fields before submit", {
        position: "top center",
        timeout: 2000,
      });
      return;
    }
    const dataParams = {
      name: nameValue,
      phone: phoneNumber,
      email: emailValue,
      desc: descValue,
    };
    setIsLoading(true);
    fetchWithPostMethod("", dataParams)
      .then((data) => {
        alertTemp.show(
          "Thanks for providing the details. Our support team will reach you soon.",
          {
            position: "top center",
            timeout: 4000,
          }
        );
        setIsLoading(false);
        setFormVisible(false);
        setFormVisible(true);
        setPhoneNumber("");
        setTimeout(() => {
          nameFieldEl.value = "";
          emailFieldEl.value = "";
          descFieldEl.value = "";
        }, 0);
      })
      .catch((err) => {
        alertTemp.error("Error occurred while submitting the information", {
          position: "top center",
          timeout: 4000,
        });
        setIsLoading(false);
      });
  };

  return (
    <section id="contact" className="contactus-component-container">
      <TitleRenderer title="Contact Us" />
      <InView
        as="div"
        onChange={(inView) =>
          !animateStatus ? setAnimateStatus(inView) : null
        }
      >
        <p className={`text-center ${animateStatus ? "start-animate-ud" : ""}`}>
          We would be happy to hear from you. To understand your requirements
          contact us on the below details.
        </p>
        {isFormVisible && (
          <Form
            name="contactUsForm"
            onSubmit={sendContactInfo}
            className="position-relative"
          >
            <FormGroup>
              <Input
                id="contact-name"
                name="name"
                placeholder="Name"
                type="text"
              ></Input>
              <Input
                id="contact-email"
                name="Email"
                placeholder="Email Address"
                type="email"
              ></Input>
              <PhoneInput
                defaultCountry="IN"
                placeholder="Contact Number"
                value={phoneNumber}
                onChange={handleNumberChange}
              />
              <Input
                id="content-description"
                name="text"
                placeholder="Description"
                type="textarea"
              />
              {/* <Label className="upload-doc-label" for="attachments">
                Upload Document
              </Label>
              <Input id="attachments" name="file" type="file" /> */}
            </FormGroup>
            <Button type="submit" disabled={isLoading} className="send-button">
              {isLoading ? "Sending..." : "Send"}
            </Button>
          </Form>
        )}
      </InView>
    </section>
  );
};

export default ContactUsComponent;
